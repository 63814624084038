import React, { useState, useEffect } from 'react';
import EmpSidebar from './EmpSidebar';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import {jwtDecode} from 'jwt-decode';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './EmpHeader.css';
 
const EmpDoc = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [employeeID, setEmployeeID] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'EmployeeDashboard') {
        navigate('/');
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    const fetchEmployeeID = async () => {
      try {
        const response = await axios.get(`https://api.payroll.yatayati.com/api/employeeID/${username}`);
        setEmployeeID(response.data.employeeID);
      } catch (error) {
        console.error('Error fetching employee ID:', error);
      }
    };
 
    if (username) {
      fetchEmployeeID();
    }
  }, [username]);
 
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
 
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };
 
  const handleDownloadPayslip = async () => {
    if (!selectedYear || !selectedMonth) {
      toast.warning('Please select both month and year before downloading.');
      return;
    }
 
    try {
      const response = await axios.get(
        `https://api.payroll.yatayati.com/Employee/payslip/${username}/${selectedYear}/${selectedMonth}`,
        { responseType: 'blob' }
      );
 
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `PaySlip_${employeeID}_${selectedMonth}_${selectedYear}.pdf`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
 
      setAnchorEl(null);
    } catch (error) {
      console.error('Error downloading payslip:', error);
      if (error.response && error.response.status === 404) {
        toast.info('No payslip found for the selected month and year.');
      } else {
        toast.error('Failed to download payslip. Please try again later.');
      }
    }
  };
 
  const handleDownloadOfferLetter = async () => {
    try {
      // Replace with actual endpoint and logic to download offer letter
      const offerLetterResponse = await axios.get(
        `https://api.payroll.yatayati.com/Employee/offerletter/${employeeID}`,
        { responseType: 'blob' }
      );
 
      const url = window.URL.createObjectURL(new Blob([offerLetterResponse.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `OfferLetter_${employeeID}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.remove();
 
      setAnchorEl(null);
    } catch (error) {
      console.error('Error downloading offer letter:', error);
      // toast.warning('Failed to download offer letter. Please try again later.');
      toast.warning('No Documents is there.');
    }
  };
 
  const generateYears = (startYear, endYear) => {
    const years = [];
    for (let year = endYear; year >= startYear; year--) {
      years.push(year);
    }
    return years;
  };
 
  const currentYear = new Date().getFullYear();
  const years = generateYears(2021, currentYear + 10); // Adjust the range as needed
 
  return (
    <>
      <EmpSidebar />
      <div className="container-1">
        <div className="content-1">
          <div className="box-1">
            <h2 className="header-1">EmployeeID: {employeeID}</h2>
 
            {/* Payslip Section */}
            <div className="section payslip-box">
              <h3 className="section-heading">Your Payslip</h3>
              <div className="filters-1">
                <select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  className="select-1"
                >
                  <option value="">Select Month</option>
                  <option value="01">January</option>
                  <option value="02">February</option>
                  <option value="03">March</option>
                  <option value="04">April</option>
                  <option value="05">May</option>
                  <option value="06">June</option>
                  <option value="07">July</option>
                  <option value="08">August</option>
                  <option value="09">September</option>
                  <option value="10">October</option>
                  <option value="11">November</option>
                  <option value="12">December</option>
                </select>
                <select value={selectedYear} onChange={handleYearChange} className="select">
                  <option value="">Select Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
                <button onClick={handleDownloadPayslip} className="download-button">Download Payslip</button>
              </div>
            </div>
 
            {/* Offer Letter Section */}
            <div className="section offer-letter-box">
              <h3 className="section-heading">other documents</h3>
              <div className="filters-1">
                <button onClick={handleDownloadOfferLetter} className="download-button">Download</button>
              </div>
            </div>
 
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
 
export default EmpDoc;
 
 
import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./HrLeaves.css";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useHighlightCriteria } from './HighlightCriteriaContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';


const Datagrid = () => {

  const { highlightCriteria } = useHighlightCriteria();
  const { setHighlightCriteria } = useHighlightCriteria();


  useEffect(() => {
    if (highlightCriteria.length > 0) {
      const timer = setTimeout(() => {
        setHighlightCriteria([]);
      }, 20000); 
  
      return () => clearTimeout(timer); 
    }
  }, [highlightCriteria, setHighlightCriteria]);
  
  useEffect(() => {
    localStorage.setItem('highlightCriteria', JSON.stringify(highlightCriteria));
  }, [highlightCriteria]);

  const isHighlighted = (row) => {
    if (!highlightCriteria || highlightCriteria.length === 0) return false;
    return highlightCriteria.some(criteria => {
      const matchID = row.EmployeeID === criteria.EmployeeID;
      const isLeaveHighlight = criteria.StartDate && criteria.EndDate;
      const isAttendanceHighlight = criteria.Date;

      if (isLeaveHighlight) {
        const matchStartDate = moment(row.StartDate).isSame(criteria.StartDate, 'day');
        const matchEndDate = moment(row.EndDate).isSame(criteria.EndDate, 'day');
        return matchID && matchStartDate && matchEndDate;
      }

      if (isAttendanceHighlight) {
        const matchDate = moment(row.Date).isSame(criteria.Date, 'day');
        return matchID && matchDate;
      }

      return false;
    });
  };


  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'HrHome') {
        navigate('/');
      }
    }
  }, []);


  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (username) 
    fetchData();
  }, [username]);


  const fetchData = (() => {
    axios.get(`https://api.payroll.yatayati.com/attendance/check/${username}`)
    .then((response) => {
      const sortedData = response.data.sort((a, b) => new Date(a.Date) - new Date(b.Date));
      setData(sortedData);
        // setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching attendance:", error);
      });
  });
 

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, Math.ceil(filteredData.length / rowsPerPage)));
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    
    const formattedDate = moment(selectedRow.Date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
 
    axios.put(`https://api.payroll.yatayati.com/attendance/${selectedRow.EmployeeID}/${formattedDate}`,
        editedRow
      )
      .then((response) => {
        console.log(response.data.message);
        // Update only the selected row in the state
        setData((prevData) => {
          return prevData.map((item) => {
            if (item.EmployeeID === editedRow.EmployeeID && item.Date === selectedRow.Date) {
                         // Clear the comment if status changed
            const updatedRow = { ...item, ...editedRow };
            if (item.Status !== editedRow.Status) {
              updatedRow.Comment = "";
            }
            return updatedRow;
            } else {
              return item;
            }
          });
        });
        toast.success("Record updated successfully");
      })
      .catch((error) => {
        console.error("Error updating attendance:", error);
        toast.error("Failed to update record");
      })
      .finally(() => {
      setEditDialogOpen(false);
        setEditedRow(null);
        handleClose();
      });
  };

  const handleEditFormChange = (event, field) => {
    const { value } = event.target;
    setEditedRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleEditOpen = (row) => {
    setEditedRow({ ...row, Date: moment(row.Date).format('YYYY-MM-DD') });
    setEditDialogOpen(true);
  };

  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      const employeeIdToDelete = selectedRow.EmployeeID;
      const formattedDate = moment(selectedRow.Date).format("YYYY-MM-DDTHH:mm:ss.SSS[Z]");
      axios
        .delete(`https://api.payroll.yatayati.com/attendance/${employeeIdToDelete}/${formattedDate}`)
        .then((response) => {
          console.log(response.data.message);
          fetchData();
          toast.success("Record deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting attendance:", error);
          toast.error("Failed to delete record");
        })
        .finally(() => {
          setDeleteConfirmationOpen(false);
          handleClose();
        });
    }
  };

  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  let filteredData = [...data];

  if (searchQuery) {
    filteredData = filteredData.filter(
      (item) => item.EmployeeID.toLowerCase() === searchQuery.toLowerCase()
    );
  }

  if (selectedDate) {
    const formattedDate = moment(selectedDate).format("DD-MM-YYYY");
    filteredData = filteredData.filter(
      (item) => moment(item.Date).format("DD-MM-YYYY") === formattedDate
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
        <div
          className="super-typo"
          style={{ display: "flex", justifyContent: "space-between", marginBottom:"15px", gap:"1.5rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <TextField
              type="date"
              label="Date"
              value={selectedDate}
              onChange={handleDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div style={{ display: "flex" }}>
            <TextField
              variant="outlined"
              label="EmployeeID"
              value={searchQuery}
              onChange={handleSearchChange}
              className="super-text"
            />
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell className="super-stickyCell">
                  Employee ID
                </TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Clock in</TableCell>
                <TableCell>Clock out</TableCell>
                <TableCell>Total time</TableCell>
                <TableCell>Department</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Comment</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row, index) => (
          <TableRow
          key={index}
          style={
            isHighlighted(row)
              ? { backgroundColor: 'yellow' } 
              : {}
          }
             >
                {/* <TableRow key={index}> */}
                  <TableCell className="super-stickyCell">
                    {row.EmployeeID}
                  </TableCell>
                  <TableCell>{moment(row.Date).format("DD-MM-YYYY")}</TableCell>
                  <TableCell>{row.clockIN}</TableCell>
                  <TableCell>{row.clockout}</TableCell>
                  <TableCell>{row.TotalTime}</TableCell>
                  <TableCell>{row.Department}</TableCell>
                  <TableCell>{row.Status}</TableCell>
                  <TableCell>{row.Comment || "None"}</TableCell>
                  <TableCell>
                    <Button onClick={(event) => handleClick(event, row)}>
                      <MoreHorizIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px", color: "black" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            <select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              style={{ width: "40px", height: "28px" }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>
        {selectedRow && (
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={() => handleEditOpen(selectedRow)}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
          </Menu>
        )}

        <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Edit Details</DialogTitle>
          <DialogContent>
            <form onSubmit={handleFormSubmit}>
              <TextField
                label="Employee ID"
                value={editedRow ? editedRow.EmployeeID : ""}
                onChange={(e) => handleEditFormChange(e, "EmployeeID")}
                fullWidth
                margin="normal"
                disabled
              />
              <TextField
                label="Date"
                type="date"
                value={editedRow ? editedRow.Date : ""}
                onChange={(e) => handleEditFormChange(e, "Date")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Status"
                select
                value={editedRow ? editedRow.Status : ""}
                onChange={(e) => handleEditFormChange(e, "Status")}
                fullWidth
                margin="normal"
              >
                <MenuItem value="Present">Present</MenuItem>
                <MenuItem value="Absent">Absent</MenuItem>
                <MenuItem value="Half-Day">Half-Day</MenuItem>
              </TextField>

              <TextField
                label="Comments"
                value={editedRow ? editedRow.Comment : ""}
                onChange={(e) => handleEditFormChange(e, "Comment")}
                fullWidth
                margin="normal"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </>
  );
};

export default Datagrid;

import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
import React, { useState, useEffect } from "react";
// import EmpSidebar from "./EmpSidebar";
import axios from "axios";
import moment from "moment";
import { Button } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const EmpAttendance = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [user, setUser] = useState([]);
  const [data, setData] = useState([]);
  const [timerRunning, setTimerRunning] = useState(false);
  const [clockInTime, setClockInTime] = useState(null);
  const [clockOutTime, setClockOutTime] = useState(null);
  const [clockInOutPopupOpen, setClockInOutPopupOpen] = useState(false);
  const [clockInOutMessage, setClockInOutMessage] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "EmployeeDashboard") {
        navigate("/");
      }
    }
  }, []);

  const fetchnameData = async () => {
    try {
      const response = await axios.get(
        `https://api.payroll.yatayati.com/loginattend/${username}`
      );
      const sortedData = response.data.user.sort((a, b) => new Date(a.Date) - new Date(b.Date));
      setUser(sortedData);
      resetClockInOutState();
    } catch (error) {
      console.error("Error fetching user data", error);
    }
  };

  useEffect(() => {
    if (username) {
      fetchnameData();
    }
  }, [username]);

  const resetClockInOutState = async () => {
    try {
      const response = await axios.get(`https://api.payroll.yatayati.com/attendance/${username}`);
      const attendance = response.data.attendance;
      if (attendance && attendance.isClockedIn) {
        setClockInTime(attendance.clockIn);
        setTimerRunning(true);
      } else {
        setClockInTime(null);
        setClockOutTime(null);
        setTimerRunning(false);
      }
    } catch (error) {
      console.error("Error fetching attendance data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    axios
      .get("https://api.payroll.yatayati.com/attendance")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching attendance:", error);
      });
  };

  const handleClockInOut = () => {
    if (timerRunning) {
      const clockOut = moment().format("HH:mm:ss");
      setClockOutTime(clockOut);
      const duration = calculateDuration(clockInTime, clockOut);
      saveClockOutData(clockOut, duration);
      setTimerRunning(false);
      localStorage.removeItem(`clockInTime_${username}`);
      setClockInOutMessage(`Clocked out at ${clockOut}`);
      setClockInOutPopupOpen(true);
    } else {
      const clockIn = moment().format("HH:mm:ss");
      setClockInTime(clockIn);
      setTimerRunning(true);
      localStorage.setItem(`clockInTime_${username}`, clockIn);
      setClockInOutMessage(`Clocked in at ${clockIn}`);
      setClockInOutPopupOpen(true);
      saveClockInData(clockIn);
    }
  };

  const calculateDuration = (clockInTime, clockOutTime) => {
    const start = moment(clockInTime, "HH:mm:ss");
    const end = moment(clockOutTime, "HH:mm:ss");
    const duration = moment.duration(end.diff(start));
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();
    return `${hours}h ${minutes}m`;
  };

  const saveClockInData = (clockInTime) => {
    const requestData = {
      employeeId: username,
      date: moment().format("YYYY-MM-DD"),
      clockIn: clockInTime,
      isClockedIn: true
    };

    axios
      .post("https://api.payroll.yatayati.com/clock-in", requestData)
      .then(() => {
        fetchData();
        fetchnameData();
        toast.success("Clocked in successfully");
      })
      .catch((error) => {
        console.error("Error saving clock in data:", error);
        toast.error("Error clocking in");
      });
  };

  const saveClockOutData = (clockOutTime) => {
    const clockIn = moment(clockInTime, "HH:mm:ss");
    const clockOut = moment(clockOutTime, "HH:mm:ss");
    const duration = moment.duration(clockOut.diff(clockIn));
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    let status = "Absent";

    if (hours >= 8) {
      status = "Present";
    } else if (hours >= 4) {
      status = "Half-day";
    }

    const requestData = {
      employeeId: username,
      date: moment().format("YYYY-MM-DD"),
      clockIn: clockInTime,
      clockOut: clockOutTime,
      duration: `${hours}:${minutes}:${seconds}`,
      status: status,
      comment: "",
      isClockedIn: false
    };

    axios
      .post("https://api.payroll.yatayati.com/clock-out", requestData)
      .then(() => {
        fetchData();
        setClockOutTime(clockOutTime);
        fetchnameData();
        toast.success("Attendance marked successfully");
      })
      .catch((error) => {
        console.error("Error saving clock out data:", error);
        toast.error("You have already marked attendance for this date");
      });
  };

  return (
    <div>
      {/* <EmpSidebar /> */}
      <div>
        <Button
          variant="contained"
          color={timerRunning ? "secondary" : "primary"}
          onClick={handleClockInOut}
          style={{
            marginLeft: "10px",
            marginBottom: "20px",
          }}
        >
          {timerRunning ? "Clock Out" : "Clock In"}
        </Button>

        <div style={{ display: "block", marginLeft: "20px" }}>
          {clockInTime && <p>Clock In Time: {clockInTime}</p>}
          {clockOutTime && <p>Clock Out Time: {clockOutTime}</p>}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EmpAttendance;

import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Axios from "axios";
import AdminSidebar from './AdminSidebar';
import Week from './AdminOrgNav';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const Position = (onDataEdit) => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'AdminHome') {
        navigate('/');
      }
    }
  }, []);

  const [data, setData] = useState([]);
  // const [selectedType, setSelectedType] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [addPositionOpen, setAddPositionOpen] = useState(false);
  const [newPositionID, setNewPositionID] = useState("");
  const [typeDepartment, setTypeDepartment] = useState("");
  const [newdescription, setNewDescription] = useState("");
  const [rowsPerPages, setRowsPerPage] = useState(4);
  const [currentPage, setCurrentPage] = useState(1);
  const [anchorE1, setAnchorE1] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [newPositionName, setNewPositionName] = useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);

  const fetchData = (() => {
    Axios.get(`https://api.payroll.yatayati.com/api/positions/${username}`)
      .then((response) => {
        setData(response.data);
        fetchDataDepartment();
      })
      .catch((error) => {
        console.error("Error fetching Position data:", error);
      });
  })

  const fetchDataDepartment = async () => {
    try {
      const response = await Axios.get(`https://api.payroll.yatayati.com/api/department/${username}`);
      setDepartmentData(response.data);
    } catch (error) {
      console.error("Error fetching Department data:", error);
      toast.error("Failed to fetch Department data");
    }
  };

  useEffect(() => {
    if (username){
      fetchData();
      fetchDataDepartment();
    }
  }, [username]);

  const [departmentData, setDepartmentData] = useState([]);

  const handleDataTypeChange = (e) => {
    const selectedType = e.target.value;
    setTypeDepartment(selectedType); 
  };

  const handleClick = (event, row) => {
    setAnchorE1(event.currentTarget);
    setSelectedRow(row);
  };
  const handleClose = () => {
    setAnchorE1(null);
    setSelectedRow(null);
  };


  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log("Form submitted:", editedRow);
    Axios.put(
      `https://api.payroll.yatayati.com/api/position/update/${selectedRow.id}`,
      editedRow
    )
      .then((response) => {
        fetchData();
        handleClose();
        setEditDialogOpen(false);
        setEditedRow(null);
        toast.success("Record updated successfully");
        setData((prevData) =>
          prevData.map((item) => (item.id === editedRow.id ? editedRow : item))
        );
      })
      .catch((error) => {
        console.error("Error updating record:", error);
        toast.error("Failed to update record");
      });
  };
  const handleOpenPositionDialog = () => {
    setAddPositionOpen(true);
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleCloseAddPositionDialog = () => {
    setAddPositionOpen(false);
  };
  const handleDeleteCancelled = () => {
    setDeleteConfirmationOpen(false);
  };
  const handleDeleteConfirmation = () => {
    setDeleteConfirmationOpen(true);
  };
  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      const idToDelete = selectedRow.id;
      Axios.delete(`https://api.payroll.yatayati.com/api/position/delete/${idToDelete}`)
        .then((response) => {
          const updatedData = data.filter(
            (item) => item.id !== idToDelete
          );
          setData(updatedData);
          setDeleteConfirmationOpen(false);
          handleClose();
          fetchData();
          toast.success("Record deleted successfully");
        })
        .catch((error) => {
          console.error("Error deleting record:", error);
          toast.error("Failed to delete record");
        });
    }
  };

  const handleAddPosition = () => {
    // Check if required fields are empty
    if (!typeDepartment || !newPositionName) {
      toast.warning("Please fill in all required details.");
      return;
    }
  
    console.log("Adding Position...", {
      id: newPositionID,
      DepartmentType: typeDepartment,
      PositionName: newPositionName,
      Description: newdescription,
      officeId: username,
    });
  
    Axios.post("https://api.payroll.yatayati.com/api/position/post", {
      id: newPositionID,
      DepartmentType: typeDepartment,
      PositionName: newPositionName,
      Description: newdescription,
      officeId: username,
    })
      .then((response) => {
        console.log("Position added successfully:", response.data);
        const newPosition = {
          id: newPositionID,
          DepartmentType: typeDepartment,
          PositionName: newPositionName,
          Description: newdescription,
          officeId: username,
        };
        setData((prevData) => [...prevData, newPosition]);
        // Reset input fields after successful submission
        setTypeDepartment("");
        setNewPositionName("");
        setNewDescription("");
        handleCloseAddPositionDialog();
        fetchData();
        toast.success("Position added successfully");
      })
      .catch((error) => {
        console.error("Error adding new Position:", error);
        toast.error("Failed to add Position");
      });
  };
  

  const handleEditFormChange = (event, field) => {
    const { value } = event.target;
    setEditedRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  };
 
  const handleEditOpen = (row) => {
    setEditedRow(row);
    setEditDialogOpen(true);
  };

  let filteredData = [...data];

  if (searchQuery) {
    filteredData = filteredData.filter((item) =>
      item.PositionName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPages);
  const startIndex = (currentPage - 1) * rowsPerPages;
  const endIndex = Math.min(startIndex + rowsPerPages, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <AdminSidebar />
      <Week />
      <div style={{ overflowX: "auto", maxWidth: "100%", padding: "15px" }}>
        <div className="alien">
          <div className="mars">
            <TextField
              variant="outlined"
              label="Position Name"
              value={searchQuery}
              onChange={handleSearchChange}
              className="super.text"
              style={{ marginLeft: "10px" }}>
              </TextField>
          </div>
          <div className="moon">
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={handleOpenPositionDialog}
            >
              + Add Position
            </Button>

            <Dialog
              open={addPositionOpen}
              onClose={handleCloseAddPositionDialog}
            >
              <DialogTitle>Add New Position</DialogTitle>
              <DialogContent>

              <Select
                  label="Department Type"
                  value={typeDepartment}
                  onChange={handleDataTypeChange}
                  fullWidth
                  margin="normal"
                >
                  <MenuItem value="">Select Department</MenuItem>
                  {departmentData.map((department, index) => (
                    <MenuItem key={index} value={department.DepartmentName}>
                      {department.DepartmentName}
                    </MenuItem>
                  ))}
                </Select>

                <TextField
                  label="Position Name"
                  value={newPositionName}
                  onChange={(e) => setNewPositionName(e.target.value)}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  label="Description"
                  value={newdescription}
                  onChange={(e) => setNewDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAddPositionDialog}
                  color="secondary"
                >
                  Cancel
                </Button>
                <Button onClick={handleAddPosition} color="primary">
                  Add
                </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
              <TableRow>
                <TableCell>Department Type</TableCell>
                <TableCell>Position Name</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currentData.map((row) => (
                <TableRow key={row}>
                  <TableCell>{row.DepartmentType}</TableCell>
                  <TableCell>{row.PositionName}</TableCell>
                  <TableCell>{row.Description ? row.Description : 'None'}</TableCell>
                  <TableCell>
                    <Button onClick={(event) => handleClick(event, row)}>
                      <MoreHorizIcon />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "1rem",
          }}
        >
          <div>
            <Button disabled={currentPage === 1} onClick={handlePrevPage}>
              <FaChevronLeft />
            </Button>
            <span style={{ margin: "0 10px" }}>
              Page {currentPage} of {totalPages}
            </span>
            <Button
              disabled={currentPage === totalPages}
              onClick={handleNextPage}
            >
              <FaChevronRight />
            </Button>
          </div>
          <Typography variant="body2">
            {" "}
            <select
              value={rowsPerPages}
              onChange={handleRowsPerPageChange}
              style={{ width: "40px", height: "28px" }}
            >
              <option value={4}>4</option>
              <option value={8}>8</option>
              <option value={12}>12</option>
            </select>
          </Typography>
        </div>

        {selectedRow && (
          <Menu
            anchorEl={anchorE1}
            open={Boolean(anchorE1)}
            onClose={handleClose}
          >
            <MenuItem onClick={() => handleEditOpen(selectedRow)}>Edit</MenuItem>
            <MenuItem onClick={handleDeleteConfirmation}>Delete</MenuItem>
          </Menu>
        )}

             <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Edit Details</DialogTitle>
          <DialogContent>
            <form onSubmit={handleFormSubmit}>

            <Select
              label="Department Type"
              value={editedRow ? editedRow.DepartmentType : ""}
              onChange={(e) => handleEditFormChange(e, "DepartmentType")}
              fullWidth
              margin="normal">

              <MenuItem value="">Select Department</MenuItem>
              {departmentData.map((department, index) => (
                <MenuItem key={index} value={department.DepartmentName}>
                  {department.DepartmentName}
                </MenuItem>
              ))}
            </Select>

              <TextField
                label="Position Name:"
                value={editedRow ? editedRow.PositionName : ""}
                onChange={(e) => handleEditFormChange(e, "PositionName")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Description:"
                value={editedRow ? editedRow.Description : ""}
                onChange={(e) => handleEditFormChange(e, "Description")}
                fullWidth
                margin="normal"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this record?
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteCancelled} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDeleteConfirmed} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <ToastContainer />
    </>
  );
};

export default Position;

import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TextField,
  Checkbox,
  Select,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AdminPayroll from './AdminPayroll';
import AdminSidebar from './AdminSidebar';
import Sample from './Present';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import Axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const AttendanceDetails = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    }
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'AdminHome') {
        navigate('/');
      }
    }
  }, []);

  useEffect(() => {
    Axios.get(`https://api.payroll.yatayati.com/api/all/Employee/details/${username}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Employee data:", error);
      });
  }, [username]);
  
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(4);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [pendingInsert, setPendingInsert] = useState(null);

  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleAdd = () => {
    selectedRows.forEach((row) => {
      Axios.get(`https://api.payroll.yatayati.com/api/get-attendance-count/${username}`, {
        params: { employeeID: row.EmployeeID, month: selectedMonth },
      })
        .then((response) => {
          const { totalDays, workingDays, number, totalExtraLeaves } = response.data;
          const newRecord = {
            employeeID: row.EmployeeID,
            employeeName: row.DisplayName,
            month: months[selectedMonth - 1],
            totalDays,
            workingDays,
            presentedDays: number,
            extraLeaves: totalExtraLeaves,
            officeId: username,
          };

          setPendingInsert(newRecord);

          Axios.post(`https://api.payroll.yatayati.com/api/insert-attendance-count`, newRecord)
            .then(() => {
              toast.success("Successfully inserted");
            })
            .catch((error) => {
              console.log("Error:", error.response.data);
              if (error.response && error.response.data.error === 'Record exists') {
                setOpenDialog(true);
              } else {
                console.error("Error adding attendance count:", error);
              }
            });
        })
        .catch((error) => {
          console.error("Error fetching attendance count:", error);
        });
    });
  };

  const handleConfirmReplace = () => {
    if (pendingInsert) {
      Axios.post(`https://api.payroll.yatayati.com/api/replace-attendance-count`, pendingInsert)
        .then(() => {
          toast.success("Attendance count updated successfully");
        })
        .catch((error) => {
          console.error("Error replacing attendance count:", error);
        });
    }
    setOpenDialog(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  let filteredData = [...data];

  if (searchQuery) {
    filteredData = filteredData.filter(
      (item) =>
        item.EmployeeID.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.DisplayName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  const handleCheckboxChange = (event, row) => {
    const isChecked = event.target.checked;
    setSelectedRows(prevSelectedRows => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter(selectedRow => selectedRow.EmployeeID !== row.EmployeeID);
      }
    });
  };

  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedRows(data);
    } else {
      setSelectedRows([]);
    }
  };

  // const isSelected = (row) => selectedRows.some(selectedRow => selectedRow.EmployeeID === row.EmployeeID);

  const months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  const currentDate = new Date();

  // Extract the current month and year
  const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
  const currentYear = currentDate.getFullYear();

  return (
    <>
    <AdminSidebar />
    <AdminPayroll />
    <Sample/>
    <div
      style={{
        maxWidth: "100%",
        padding: "10px",
        marginLeft: "10px",
      }}
    >
      <div className="alien">
        <div className="mars">
          <TextField
            variant="outlined"
            label="Name & ID"
            value={searchQuery}
            onChange={handleSearchChange}
            className="super.text"
            style={{ marginLeft: "10px" }}
          />
        </div>
     
        <div className="moon" style={{display:"flex", gap:"2rem"}}>
        <div className="mars">
          <Select
            value={selectedMonth}
            onChange={handleMonthChange}
            displayEmpty
            style={{ marginLeft: "10px" }}
          >
            <MenuItem value="" disabled>Select Month</MenuItem>
            {months.map((month, index) => (
              <MenuItem key={index} value={index + 1}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </div>
<div>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleAdd}
            disabled={selectedRows.length === 0 || !selectedMonth}
          >
            + Attendance Count
          </Button>
          </div>
        </div>
      </div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead style={{ backgroundColor: "rgb(167, 165, 165)" }}>
            <TableRow>
              <TableCell >
              <Checkbox
  checked={selectedRows.length === data.length && data.length > 0}
  onChange={handleSelectAll}
  indeterminate={selectedRows.length > 0 && selectedRows.length < data.length}
/>
              </TableCell>
              <TableCell>EmployeeID</TableCell>
              <TableCell>Employee Name</TableCell>
              <TableCell>Month</TableCell>
              {/* <TableCell>Bonus</TableCell> */}
              {/* <TableCell>Action</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((row, index) => (
              <TableRow 
                key={index} 
                onClick={() => setSelectedEmployee(row)}
                style={{
                  backgroundColor: selectedEmployee === row ? 'lightgray' : 'white'
                }}>
                <TableCell >
                <Checkbox
                      checked={selectedRows.some(selectedRow =>selectedRow.EmployeeID === row.EmployeeID)}
                      onChange={(event) => handleCheckboxChange(event, row)}/>
                </TableCell>
                <TableCell>{row.EmployeeID}</TableCell>
                <TableCell>{row.DisplayName}</TableCell>
                <TableCell>{currentMonth} {currentYear}</TableCell>
                {/* <TableCell>{row.Bonus}</TableCell> */}
                {/* <TableCell>
                  <Button onClick={(event) => handleClick(event, row)}>
                    <MoreHorizIcon />
                  </Button>
                </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <div>
          <Button disabled={currentPage === 1} onClick={handlePrevPage}>
            <FaChevronLeft />
          </Button>
          <span style={{ margin: "0 10px" }}>
            Page {currentPage} of {totalPages}
          </span>
          <Button
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
          >
            <FaChevronRight />
          </Button>
        </div>
        <Typography variant="body2">
          {" "}
          <Select
            value={rowsPerPage}
            onChange={handleRowsPerPageChange}
            style={{ width: "60px", height: "28px" }}
          >
            <MenuItem value={4}>4</MenuItem>
            <MenuItem value={8}>8</MenuItem>
            <MenuItem value={12}>12</MenuItem>
          </Select>
        </Typography>
      </div>
      <ToastContainer/>

      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
      >
        <DialogTitle>Attendance Count Exists</DialogTitle>
        <DialogContent>
          <DialogContentText>
            An attendance count already exists for the selected employee and month. If you proceed, the existing count will be deleted and replaced with the new one. Do you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmReplace} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
    </>
  );
};

export default AttendanceDetails;

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faClock } from "@fortawesome/free-solid-svg-icons";
import Chart from "chart.js/auto";
import "./SuperHome.css";
import SuperSidebar from "./SuperSidebar";
import {jwtDecode} from "jwt-decode";
import Cookies from 'js-cookie';
import Axios from "axios";
import Popup from "../Popup";
 
const Dashboard = () => {
  const [totalOffices, setTotalOffices] = useState(0);
  const[lastRegister,setLastRegister] =useState("");
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [showPopup, setShowPopup] = useState(true);
  const [clientDetails, setClientDetails] = useState([]);

  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "SuperHome") {
      navigate("/");
    }
  }, [navigate]);
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "SuperHome") {
        navigate("/");
      } else {
        setShowWelcomeMessage(true);
      }
    }
  }, [navigate]);
 
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get("token");
        const response = await Axios.get("https://api.payroll.yatayati.com/SuperHome", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
 
    fetchUserData();
  }, [navigate]);
 
  useEffect(() => {
    const fetchTotalOffices = async () => {
      try {
        const response = await Axios.get("https://api.payroll.yatayati.com/total-offices");
        setTotalOffices(response.data.totalOffices);
      } catch (error) {
        console.error("Error fetching total offices:", error);
      }
    };
 
    fetchTotalOffices();
  }, []);
 
  useEffect(() => {
    const fetchLastRegister = async () => {
      try {
        const response = await Axios.get("https://api.payroll.yatayati.com/last-registered-office");
        setLastRegister(response.data.lastRegister);
      } catch (error) {
        console.error("Error fetching Last Register:", error);
      }
    };
 
    fetchLastRegister();
  }, []);

  useEffect(() => {
    const fetchClientDetails = async () => {
      try {
        const response = await Axios.get(
          "https://api.payroll.yatayati.com/client-details"
        );
        setClientDetails(response.data);
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    };
 
    fetchClientDetails();
  }, []);
 
  const cardsData = [
    { title: "Total Offices", value: totalOffices, color: "#6692b0", icon: faUsers },
    { title: "Last Register", value: lastRegister, color: "#6692b0", icon: faClock },
  ];
 
  const barChartRef = useRef(null);
  const barChartInstance = useRef(null);

  useEffect(() => {
    const fetchChartData = async () => {
      try {
        const response = await Axios.get("https://api.payroll.yatayati.com/office-employee-count");
        const { data } = response.data;

        const aggregatedData = data.reduce((acc, curr) => {
          const { office_name, employee_count } = curr;
          if (acc[office_name]) {
            acc[office_name] += employee_count;
          } else {
            acc[office_name] = employee_count;
          }
          return acc;
        }, {});

        const offices = Object.keys(aggregatedData);
        const employeeCounts = Object.values(aggregatedData);

        console.log("Aggregated Offices:", offices);
        console.log("Aggregated Employee Counts:", employeeCounts);

        const barChartData = {
          labels: offices,
          datasets: [
            {
              label: "Number of Employees",
              data: employeeCounts,
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              borderColor: "rgba(75, 192, 192, 1)",
              borderWidth: 1,
            },
          ],
        };

        const barChartOptions = {
          responsive: true,
          maintainAspectRatio: false,
          scales: { y: { beginAtZero: true } },
        };

        if (barChartInstance.current) {
          barChartInstance.current.destroy();
        }

        const barCtx = barChartRef.current.getContext("2d");
        barChartInstance.current = new Chart(barCtx, {
          type: "bar",
          data: barChartData,
          options: barChartOptions,
        });
      } catch (error) {
        console.error("Error fetching chart data:", error);
      }
    };

    fetchChartData();

    return () => {
      if (barChartInstance.current) {
        barChartInstance.current.destroy();
      }
    };
  }, []);

 
  return (
    <div>
      {showPopup && (
        <Popup message={`Welcome back`} onClose={() => setShowPopup(false)} />
      )}
        <SuperSidebar />
      <div className="super-total">
      {/* <h3 style={{margin:"15px"}}>SUPER ADMIN DASHBOARD</h3> */}
        <div className="super-dashboard-container">
          <div className="super-charts-container">
            <div className="super-cards-container">
              {cardsData.map((card, index) => (
                <div
                  key={index}
                  className="super-card"
                  style={{ backgroundColor: card.color }}
                >
                  <h3>{card.title}</h3>
                  <p style={{marginLeft:"15px"}}>{card.value}</p>
                  {/* <FontAwesomeIcon icon={card.icon} className="super-icon" /> */}
                </div>
              ))}
            </div>
            <div className="super-chart-container">
              <canvas ref={barChartRef} className="super-chart"></canvas>
            </div>
           
          </div>
         <div className="super-data-grid">
            <h2>Client Details</h2>
            <table>
              <thead>
                <tr>
                  <th>Client ID</th>
                  <th>Name</th>
                  <th>Address</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {clientDetails.map((client, index) => (
                  <tr key={index}>
                    <td>{client.OfficeID}</td>
                    <td>{client.ClientName}</td>
                    <td>{client.ClientAddress}</td>
                    <td
                      style={{
                        color: client.Status === "Active" ? "green" : "red",
                      }}
                    >
                      {client.Status}
                    </td>{" "}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
       
        </div>
      </div>
    </div>
  );
};
 
export default Dashboard;
 
import React, { useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsFillGrid3X3GapFill, BsPeopleFill, BsListCheck, BsJustify, BsPersonCircle } from 'react-icons/bs'; 
import { GiReceiveMoney, GiPayMoney } from "react-icons/gi"; 
import { MdHolidayVillage, MdLogout, MdHome } from "react-icons/md";
import { IoDocuments } from "react-icons/io5";
import { BsEscape } from "react-icons/bs"; 
import { AiOutlineUser } from "react-icons/ai";
import Cookies from 'js-cookie';
import './EmpSidebar.css';
import axios from "axios";
import { jwtDecode } from "jwt-decode";

function Sidebar() {
  const navigate = useNavigate();
  const sidebarRef = useRef(null);
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [username, setUsername] = useState("");
  const [role, setRole] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [employeeStatus, setEmployeeStatus] = useState("");
 
  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      setEmployeeStatus(decoded.employeeStatus);
 
      if (decoded.role !== "EmployeeDashboard") {
        navigate("/");
      } else if (decoded.employeeStatus === "Blocked") {
        navigate("/EmpDoc");
      }
    }
  }, [navigate]);
 
  // useEffect(() => {
  //   const token = Cookies.get("token");
  //   if (!token) {
  //     navigate("/");
  //   } else {
  //     const decoded = jwtDecode(token);
  //     setUsername(decoded.username);
  //     setRole(decoded.role);
  //     if (decoded.role !== "EmployeeDashboard") {
  //       navigate("/");
  //     }
  //   }
  // }, [navigate]);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setOpenSidebarToggle(false);
    }
  };

  const handleItemClick = () => {
    setOpenSidebarToggle(false);
  };

  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    setShowLogoutModal(true); 
  };

  const handleCloseModal = () => {
    setShowLogoutModal(false);
  };

  const handleConfirmLogout = () => {
    Cookies.remove('token'); 
    navigate('/'); 
  };

  const fetchOfficeName = async () => {
    try {
      console.log("Fetching office name for username:", username);
      const response = await axios.get(
        `https://api.payroll.yatayati.com/officeDisplay/${username}`
      );
      console.log("Response from backend:", response);
      setOfficeName(response.data.officeName);
    } catch (error) {
      console.error("Error fetching office name:", error);
    }
  };
 
  useEffect(() => {
    if (username) {
      fetchOfficeName();
    }
  }, [username]);

  return (
    <>
      <aside
        id="sidebar"
        ref={sidebarRef}
        className={`${openSidebarToggle ? 'sidebar-responsive' : ''} ${
          !openSidebarToggle ? 'sidebar-closed' : ''
        }`}
      >
        <div className="sidebar-title">
          <div className="sidebar-brand">
            <AiOutlineUser className="icon_header" /> EMPLOYEE
          </div>
        </div>

        <ul className="sidebar-list">
          <Link to="/EmployeeDashboard"
            className={`sidebar-link ${employeeStatus === 'Blocked' ? 'disabled ' : ''}`}
            activeClassName="active-link" onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <MdHome className="icon" /> Dashboard
            </li>
          </Link>
         
          <Link to="/EmpProfile" className={`sidebar-link ${employeeStatus === 'Blocked' ? 'disabled ' : ''}`}
            onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsPeopleFill className="icon" /> Profile
            </li>
          </Link>
                   
          <Link to="/EmpAttendance" className={`sidebar-link ${employeeStatus === 'Blocked' ? 'disabled ' : ''}`}
            onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsFillGrid3X3GapFill className="icon" /> Attendance
            </li>
          </Link>
          
          <Link to="/EmpLeave" className={`sidebar-link ${employeeStatus === 'Blocked' ? 'disabled ' : ''}`}
            onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <BsEscape className="icon" /> Leave
            </li>
          </Link>

          <Link to="/EmpPayHeader" className={`sidebar-link ${employeeStatus === 'Blocked' ? 'disabled ' : ''}`}
            onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <GiPayMoney className='icon'/> MyPay
            </li>
          </Link>
          
          <Link to="/EmpHolidays" className={`sidebar-link ${employeeStatus === 'Blocked' ? 'disabled ' : ''}`}
            onClick={handleItemClick}>
            <li className="sidebar-list-item">
              <MdHolidayVillage className="icon" /> Holidays
            </li>
          </Link>

          <Link
            to="/EmpDoc"
            className="sidebar-link"
            onClick={handleItemClick}
          >
            <li className="sidebar-list-item">
            <IoDocuments className="icon" /> Documents
            </li>
          </Link>

          <Link to="" className="sidebar-link" onClick={handleItemClick}>
          <li className="sidebar-list-item" onClick={handleLogout}>
            <MdLogout className="icon" /> LogOut
          </li>
          </Link>
        </ul>
      </aside>
      
      <header className='header' >
        <div className='menu-icon' >
          <BsJustify className='icon' onClick={OpenSidebar} />
          <div className="office-container">
            {officeName && <span className="office-name">{officeName}</span>}
          </div>
        </div>
      </header>
   
        {showLogoutModal && (
          <div className="modal-backdrop" style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            zIndex: 1000
          }}>
            <div className="modal-content" style={{
              backgroundColor: '#fff',
              padding: '20px',
              borderRadius: '5px',
              width: '90%',
              maxWidth: '400px',
              boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
            }}>
              <h4 className="modal-title">Confirm Logout</h4>
              <p>Are you sure you want to logout?</p>
              <div className="modal-footer" style={{ textAlign: 'right' }}>
                <button onClick={handleCloseModal} className="btn btn-secondary" style={{ marginRight: '10px' }}>
                  Cancel
                </button>
                <button onClick={handleConfirmLogout} className="btn btn-primary">
                  OK
                </button>
              </div>
            </div>
          </div>        
      )}
    </>
  );
}
 
export default Sidebar;

import React, { useEffect, useState } from 'react';
import EmpSidebar from './EmpSidebar'
import { Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import moment from 'moment';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import  Axios  from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';

const EmpHolidays = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'EmployeeDashboard') {
        navigate('/');
      }
    }
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(""); 

  useEffect(() => {
    Axios.get(`https://api.payroll.yatayati.com/HR/holiday/get/${username}`).then ((response) => {
      setData(response.data);
    })
    .catch((error) => {
      console.error("Error fetching Assets data:", error);
    });
  }, [username]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  }

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  let filteredData = [...data];

  if (searchQuery) {
    filteredData = filteredData.filter(
      (item) => item.HolidayName.toLowerCase() === searchQuery.toLowerCase()
    );
  }

  if (selectedMonth) {
    const [year, month] = selectedMonth.split("-");
    filteredData = filteredData.filter(
      item => moment(item.Date).format("YYYY") === year &&
              moment(item.Date).format("MM") === month
    );
  }

  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <>
      <EmpSidebar/>
      <>
        <div className='main-container'>
          <h1 className='header1'>Check Holidays</h1>
        </div>
        <div style={{padding:"15px"}}>
          <div style={{display:"flex", justifyContent:"space-between", marginBottom:"15px"}}>
          <div>
          <TextField
            variant='outlined'
            label="Month"
            type="month"
            value={selectedMonth}
            onChange={handleMonthChange}
            style={{ marginLeft: '10px' }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          </div>
          <div>
          <TextField 
            variant="outlined"
              label="Search"
              value={searchQuery}
              onChange={handleSearchChange}
              className="super-text"
        style={{marginLeft: '10px'}}/>
          </div>
          </div>

          <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead style={{ backgroundColor: 'rgb(167, 165, 165)' }}>
            <TableRow>
              
              <TableCell className="super-stickyCell">Holiday Name</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Day</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((row, index) => (
              <TableRow key={index}>
                
                <TableCell className="super-stickyCell">{row.HolidayName}</TableCell>
                <TableCell>{moment(row.Date).format(
                        "DD-MM-YYYY")}</TableCell>
                <TableCell>{row.Day}</TableCell>
                <TableCell>{row.Location}</TableCell>
                <TableCell>{row.Description || 'None'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
        <div>
          <Button disabled={currentPage === 1} onClick={handlePrevPage}><FaChevronLeft /></Button>
          <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
          <Button disabled={currentPage === totalPages} onClick={handleNextPage}><FaChevronRight /></Button>
        </div>
        <Typography variant="body2">
          {' '}
          <select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: '40px', height: '28px' }}>
            <option value={4}>4</option>
            <option value={8}>8</option>
            <option value={12}>12</option>
          </select>
        </Typography>
      </div>

        </div>
      </>
    </>
  )
}

export default EmpHolidays
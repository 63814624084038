import React, { useState } from "react";
import "./Login.css";
import AlertMessage from "./AlertMessage";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
// import { FaEye, FaEyeSlash } from "react-icons/fa";
 
const LoginPage = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertSeverity, setAlertSeverity] = useState("error");
  const [alertMessage, setAlertMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
 
  const handleAlertClose = () => {
    setAlertOpen(false);
  };
 
  // const handleLogin = async () => {
  //   if (!username || !password) {
  //     setAlertSeverity("warning");
  //     setAlertMessage("Please fill in all fields.");
  //     setAlertOpen(true);
  //     return;
  //   }
  //   setLoading(true);
  //   try {
  //     const response = await axios.post("https://api.payroll.yatayati.com/loginn", {
  //       username,
  //       password,
  //     });
  //     if (response.data.success) {
  //       const role = response.data.role;
  //       const displayName = response.data.displayName;
  //       setAlertSeverity("success");
  //       setAlertMessage(`Welcome, ${displayName}!`);
  //       setAlertOpen(true);
  //       localStorage.setItem("role", role);
  //       Cookies.set("token", response.data.token);
  //       sessionStorage.setItem('loginTime', new Date().toISOString());
  //       setLoggedIn(true);
  //       setTimeout(() => {
  //         navigate(`/${role.toLowerCase()}`, {
  //           state: { username: displayName, role },
  //         });
  //       }, 1000);
  //     } else {
  //       setAlertSeverity("error");
  //       setAlertMessage("Invalid credentials");
  //       setAlertOpen(true);
  //     }
  //   } catch (error) {
  //     console.error("Error during login:", error);
  //     setAlertSeverity("error");
  //     setAlertMessage(
  //       "An error occurred during login. Please try again later."
  //     );
  //     setAlertOpen(true);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
 
    const handleLogin = async () => {
    if (!username || !password) {
      setAlertSeverity("warning");
      setAlertMessage("Please fill in all fields.");
      setAlertOpen(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post("https://api.payroll.yatayati.com/loginn", {
        username,
        password,
      });
 
      if (response.data.success) {
        const { role, displayName, status } = response.data;
        setAlertSeverity("success");
        setAlertMessage(`Welcome, ${displayName}!`);
        setAlertOpen(true);
        localStorage.setItem("role", role);
        Cookies.set("token", response.data.token); // Ensure token handling if applicable
        localStorage.setItem("employeeStatus", status);
        sessionStorage.setItem("loginTime", new Date().toISOString());
        setLoggedIn(true);
        setTimeout(() => {
          navigate(`/${role.toLowerCase()}`, {
            state: { username: displayName, role, status },
          });
        }, 1000);
      } else {
        setAlertSeverity("error");
        setAlertMessage(response.data.message || "Invalid credentials");
        setAlertOpen(true);
      }
    } catch (error) {
      console.error("Error during login:", error);
      setAlertSeverity("error");
      setAlertMessage(
        "An error occurred during login. Please try again later."
      );
      setAlertOpen(true);
    } finally {
      setLoading(false);
    }
  };
  
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };
 
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 
  return (
    <>
      {!loggedIn ? (
        <div className="login-bg">
          <div className="login-page">
            <div>
              <h2>Login</h2>
              <div className="input-container">
                <label htmlFor="username">Username</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  onKeyPress={handleKeyPress}
                  className="custom-input"
                />
              </div>
              <div className="input-container">
                <label htmlFor="password">Password</label>
                <div className="password-input">
                  <input
                    type="text"
                    id="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyPress={handleKeyPress}
                    // className="custom-input"
                    className={`custom-input ${showPassword ? 'show' : 'hide'}`}
                  />
                  <button
                    className="toggle-password"
                    onClick={togglePasswordVisibility}
                  >
                      {showPassword ? '🙈' : '👁️'}
                    {/* {showPassword ? <FaEyeSlash /> : <FaEye />} */}
                  </button>
                </div>
              </div>
              <div className="forgot-password">
                <a href="/ForgotPassword">Forgot Password?</a>
              </div>
              <button className="login-btn" onClick={handleLogin}>
                {loading ? "Logging in..." : "Login"}
              </button>
            </div>
            <AlertMessage
              open={alertOpen}
              severity={alertSeverity}
              message={alertMessage}
              handleClose={handleAlertClose}
            />
          </div>
        </div>
      ) : (
        <div className="loader-container">
          <div className="loader"></div>
          <div className="loader-text">Loading...</div>
        </div>
      )}
    </>
  );
};
 
export default LoginPage;
import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

const Gender = () => {
  const navigate = useNavigate();

  const [role, setRole] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");

    if (!token) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    
    const token = Cookies.get("token");

    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);

      setUsername(decoded.username);

      setRole(decoded.role);

      if (decoded.role !== "AdminHome") {
        navigate("/");
      }
    }
  }, []);

  const [data, setData] = useState([]);
  const [username, setUsername] = useState([]);

  useEffect(() => {
    fetchGenderDistribution(username);
  }, [username]);

  const fetchGenderDistribution = async (username) => {
    try {
      const response = await axios.get(`https://api.payroll.yatayati.com/admin/gender/${username}`);
      const formattedData = Object.keys(response.data).map(key => ({
        name: key.charAt(0).toUpperCase() + key.slice(1),
        value: response.data[key]
      }));
      setData(formattedData); // Correctly setting the data state here
    } catch (error) {
      console.error('Error fetching gender distribution:', error);
    }
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28'];

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div style={{ width: '100%', height: 260 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip formatter={(value, name) => [`${value}`, `${name}`]} />
          <Legend />
        </PieChart>
      </ResponsiveContainer>
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        {data.map((entry, index) => ( // Correctly iterating over the data state here
          <div key={`legend-${index}`} style={{ color: COLORS[index % COLORS.length], fontSize: '16px' }}>
            {/* {entry.name}: {entry.value} */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gender;

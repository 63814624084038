import React, { useState, useEffect } from "react";
import { BsBriefcase, BsPeopleFill } from "react-icons/bs";
import { BiSolidDryer } from "react-icons/bi";
import "react-circular-progressbar/dist/styles.css";
import { Bar, PolarArea } from "react-chartjs-2";
import "./EmpHome.css";
import EmpSidebar from "./EmpSidebar";
import Popup from "../../Popup";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
 
const Home = () => {
  const [role, setRole] = useState("");
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [attendanceCardData, setAttendanceCardData] = useState(null);
  const [attendanceChartData, setAttendanceChartData] = useState(null);
  const [leaveData, setLeaveData] = useState(null);
  const [leaveCountByMonth, setLeaveCountByMonth] = useState([]);
  const [bonusData, setBonusData] = useState(null);
  const [requestCount, setRequestCount] = useState(0);
  const [totalAttendanceTime, setTotalAttendanceTime] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [employeeName,setEmployeeName] =useState('');
 
  useEffect(() => {
    const role = localStorage.getItem("role");
    if (role !== "EmployeeDashboard") {
      navigate("/");
    }
  }, [navigate]);

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      setDisplayName(decoded.displayName);
      if (decoded.role !== "EmployeeDashboard") {
        navigate("/");
      } else {
        setShowWelcomeMessage(true);
      }
    }
  }, [navigate]);

  useEffect(() => {
    const fetchEmployeeName = async () => {
      try {
        const response = await Axios.get(`https://api.payroll.yatayati.com/api/employeeName/${username}`);
        setEmployeeName(response.data.employeeName);
      } catch (error) {
        console.error("Error fetching employee name:", error);
      }
    };
 
    fetchEmployeeName();
  }, [username]);
 
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = Cookies.get("token");
        const response = await Axios.get(
          "https://api.payroll.yatayati.com/EmployeeDashboard",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(response.data);
      } catch (error) {
        console.error("Error:", error);
        if (error.response && error.response.status === 401) {
          navigate("/");
        }
      }
    };
 
    fetchUserData();
  }, [navigate]);
 
  const fetchAttendanceCardData = async (employeeId) => {
    try {
      const response = await Axios.get(
        `https://api.payroll.yatayati.com/api/attendance/card/${employeeId}`
      );
      console.log("Attendance Data:", response.data);
      setAttendanceCardData(response.data);
 
      // Calculate total attendance time
      const totalAttendance = calculateTotalAttendance(response.data);
      setTotalAttendanceTime(totalAttendance);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };
 
  const fetchAttendanceChartData = async (employeeId) => {
    try {
      const response = await Axios.get(
        `https://api.payroll.yatayati.com/api/attendance/chart/${employeeId}`
      );
      console.log("Attendance Data:", response.data);
      setAttendanceChartData(response.data);
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };
 
  const calculateTotalAttendance = (attendanceData) => {
    if (!attendanceData) return "Loading...";
 
    let totalMinutes = 0;
    attendanceData.forEach((entry) => {
      const [hours, minutes] = entry.TotalTime.split("h ");
      totalMinutes += parseInt(hours) * 60 + parseInt(minutes);
    });
 
    const totalHours = Math.floor(totalMinutes / 60);
    const remainingMinutes = totalMinutes % 60;
    return `${totalHours}h ${remainingMinutes}m`;
  };
 
  const fetchLeaveData = async (employeeId) => {
    try {
      const response = await Axios.get(
        `https://api.payroll.yatayati.com/api/leaves/${employeeId}`
      );
      const filteredLeaves = response.data.filter(
        (leave) => leave.LeaveStatus !== "Pending"
      );
 
      console.log("Leave Data:", filteredLeaves);
      setLeaveData(filteredLeaves);
 
      const countByMonth = calculateLeavesByMonth(filteredLeaves);
      setLeaveCountByMonth(countByMonth);
    } catch (error) {
      console.error("Error fetching leave data:", error);
    }
  };
 
  const fetchBonusData = async (employeeId) => {
    try {
      const response = await Axios.get(`https://api.payroll.yatayati.com/get-bonus/${employeeId}`);
      setBonusData(response.data);
    } catch (error) {
      console.error("Error fetching bonus data:", error);
    }
  };
 
  const fetchComments = async (employeeId) => {
    try {
      const attendanceResponse = await Axios.get(
        `https://api.payroll.yatayati.com/api/attendance/comments/${employeeId}`
      );
      console.log("Attendance Comments:", attendanceResponse.data);
 
      const leaveResponse = await Axios.get(
        `https://api.payroll.yatayati.com/api/leave/comments/${employeeId}`
      );
      console.log("Leave Comments:", leaveResponse.data);
 
      const attendanceComments = attendanceResponse.data
        .map((entry) => entry.Comment)
        .filter((comment) => comment.trim() !== "");
 
      const leaveComments = leaveResponse.data
        .map((entry) => entry.Comments)
        .filter((comment) => comment.trim() !== "");
      const allComments = [...attendanceComments, ...leaveComments];
      const totalComments = allComments.length;
      setRequestCount(totalComments);
 
      console.log("Total Comments:", totalComments);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
 
  useEffect(() => {
    if (username) {
      fetchAttendanceCardData(username);
      fetchAttendanceChartData(username);
      fetchLeaveData(username);
      fetchBonusData(username);
      fetchComments(username);
    }
  }, [username]);
 
  const calculateLeavesByMonth = (data) => {
    if (!data) return [];
    const leavesByMonth = new Array(12).fill(0);
    data.forEach((leave) => {
      const month = new Date(leave.StartDate).getMonth();
      leavesByMonth[month]++;
    });
 
    return leavesByMonth;
  };


  const chartData = {
    labels: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    datasets: [
      {
        label: "Leave Count by Month",
        data: leaveCountByMonth,
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(154, 262, 135, 0.6)",
          "rgba(355, 112, 46, 0.6)",
          "rgba(175, 92, 92, 0.4)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
          "rgba(53, 202, 155, 0.9)",
 
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 155, 0.9)",
          "rgba(255, 159, 64, 0.6)",
        ],
 
        borderWidth: 1,
      },
    ],
    options: {
      legend: {
        position: "right",
        align: "start",
        labels: {
          padding: 20,
        },
      },
    },
  };
 
const generateChartData = () => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
 
    const monthlyData = {};
 
    monthNames.forEach((month) => {
      monthlyData[month] = { present: 0, absent: 0 };
    });
 
    if (attendanceChartData) {
      const currentYear = new Date().getFullYear();
 
      attendanceChartData.forEach((record) => {
        const recordDate = new Date(record.Date);
        const recordYear = recordDate.getFullYear();
 
        if (recordYear === currentYear) {
          const monthIndex = recordDate.getMonth();
          const monthName = monthNames[monthIndex];
          if (record.Status === "Present") {
            monthlyData[monthName].present++;
          } else {
            monthlyData[monthName].absent++;
          }
        }
      });
    }
    const labels = Object.keys(monthlyData);
    const presentData = labels.map((month) => monthlyData[month].present);
    const absentData = labels.map((month) => monthlyData[month].absent);
 
    return {
      labels,
      datasets: [
        {
          label: "Present",
          backgroundColor: "rgba(75, 192, 192, 0.2)",
          borderColor: "rgba(75, 192, 192, 1)",
          borderWidth: 1,
          data: presentData,
        },
        {
          label: "Absent",
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgba(255, 99, 132, 1)",
          borderWidth: 1,
          data: absentData,
        },
      ],
    };
  };

  useEffect(() => {
    const loginTime = sessionStorage.getItem('loginTime');
    if (loginTime) {
      setShowPopup(true);
      sessionStorage.removeItem('loginTime');
    }
  }, []);
 
  return (
    <>
    {showPopup && (
        <Popup message={`Welcome back ${employeeName}`} onClose={() => setShowPopup(false)} />
      )}
      <EmpSidebar />
      <div className="emp-container">
        {/* <div className="emp-header">
          <h3> Welcome Employee</h3>
        </div> */}
        <div className="emp-card-container">
          <div className="emp-card">
            <BsPeopleFill className="emp-icon1" />
            <h6>Employees Attendance</h6>
            {attendanceCardData &&
              attendanceCardData.map((record, index) => (
                <div key={index}>
                  <p>{record.TotalTime}</p>
                </div>
              ))}
          </div>
 
          <div className="emp-card">
            <BsBriefcase />
            <h6>Requests</h6>
            <h2>{requestCount}</h2>
          </div>
          <div className="emp-card">
            <BiSolidDryer />
            <h6>Employee Bonus</h6>
            <h2>{bonusData ? bonusData.bonus : 0}</h2>
          </div>
        </div>
        <div className="emp-content">
          <div className="emp-chart-2">
            <h6 className="emp-p1">Leaves Applied Till Now</h6>
            <PolarArea data={chartData} className="polarchart" />
          </div>
          <div className="emp-chart">
            <h6 className="emp-p">Attendance</h6>
            <Bar data={generateChartData()} />
          </div>
        </div>
      </div>
    </>
  );
};
 
export default Home;
 
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
import SuperNav from "./SuperNav";

const Add = () => {
  const [clientName, setClientName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [clientAddress, setClientAddress] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [officeAddress, setOfficeAddress] = useState("");
  const [officeID, setOfficeID] = useState("");
  const [date, setDate] = useState("");
  const [clientId, setClientID] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [officeIDStrength, setOfficeIDStrength] = useState("");

  const navigate = useNavigate();
  const [role, setRole] = useState("");
  const [username, setUsername] = useState("");

  useEffect(() => {
    const token = Cookies.get("token");
    if (!token) {
      navigate("/");
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== "SuperHome") {
        navigate("/");
      }
    }
  }, [navigate]);

  const handleSubmit = (e) => {
    e.preventDefault();
    toast.warning("Please wait, inserting data...");
    setIsSubmitting(true);

    const formData = {
      ClientName: clientName,
      ClientAddress: clientAddress,
      OfficeName: officeName,
      PhoneNumber: phoneNumber,
      Date: date,
      ClientEmail: clientEmail,
      OfficeID: officeID,
      OfficeAddress: officeAddress,
    };

    axios
      .post(`https://api.payroll.yatayati.com/super-details`, formData)
      .then((response) => {
        console.log(response.data.message);
        toast.success("Record inserted successfully");
        resetFormFields();
      })
      .catch((error) => {
        console.error("Error inserting SuperDetails:", error);
        if (error.response && error.response.status === 409) {
          toast.error("OfficeID already exists. Please provide a different OfficeID.");
        } else {
          toast.error("Failed to insert record.");
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const resetFormFields = () => {
    setClientName("");
    setPhoneNumber("");
    setClientAddress("");
    setOfficeName("");
    setClientEmail("");
    setOfficeAddress("");
    setOfficeID("");
    setDate("");
    setClientID("");
  };

  const handleOfficeIDChange = (e) => {
    const value = e.target.value;
    setOfficeID(value);
  
    const hasNumber = /\d/;
    const hasNonZeroNumber = /[1-9]/;
    if (hasNumber.test(value) && hasNonZeroNumber.test(value)) {
      setOfficeIDStrength("Strong");
    } else {
      setOfficeIDStrength("Too weak, please add numbers");
    }
  };

  return (
    <>
      <SuperNav />
      <div>
        <div className="form-container">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="clientName" className="form-label">
                  Client Name
                </label>
                <input
                  type="text"
                  id="clientName"
                  name="clientName"
                  className="form-input"
                  value={clientName}
                  onChange={(e) => setClientName(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="clientEmail" className="form-label">
                  Client Email
                </label>
                <input
                  type="text"
                  id="clientEmail"
                  name="clientEmail"
                  className="form-input"
                  value={clientEmail}
                  onChange={(e) => setClientEmail(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="phoneNumber" className="form-label">
                  Phone Number
                </label>
                <input
                  type="text"
                  id="phoneNumber"
                  name="phoneNumber"
                  className="form-input"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="clientAddress" className="form-label">
                  Client Address
                </label>
                <input
                  type="text"
                  id="clientAddress"
                  name="clientAddress"
                  className="form-input"
                  value={clientAddress}
                  onChange={(e) => setClientAddress(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="officeName" className="form-label">
                  Office Name
                </label>
                <input
                  type="text"
                  id="officeName"
                  name="officeName"
                  className="form-input"
                  value={officeName}
                  onChange={(e) => setOfficeName(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="date" className="form-label">
                  Date
                </label>
                <input
                  type="date"
                  id="date"
                  name="date"
                  className="form-input"
                  value={date}
                  onChange={(e) => setDate(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                  <label htmlFor="officeID" className="form-label">
                    Office ID
                  </label>
                  <input
                    type="text"
                    id="officeID"
                    name="officeID"
                    className="form-input"
                    value={officeID}
                    onChange={handleOfficeIDChange}
                    required
                    style={{ width: "310px" }}
                  />
                  {/* {officeIDStrength && (
                    <p className="strength-message" style={{ color: officeIDStrength === "Too weak, please add numbers" ? "red" : "black" }}>
                      {officeIDStrength}
                    </p>
                  )} */}
                </div>

            </div>

            <div className="form-group">
              <label htmlFor="officeAddress" className="form-label">
                Office Address
              </label>
              <input
                type="text"
                id="officeAddress"
                name="officeAddress"
                className="form-input"
                value={officeAddress}
                onChange={(e) => setOfficeAddress(e.target.value)}
                required
              />
            </div>

            <button type="submit" className="submit-button" disabled={isSubmitting}>
              {isSubmitting ? "Saving..." : "Save"}
            </button>
          </form>
          <ToastContainer />
        </div>
      </div>
    </>
  );
};

export default Add;

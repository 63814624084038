import React, { useEffect, useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from '@mui/material';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import AdminSidebar from './AdminSidebar';
import Week from './AdminOrgNav';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { jwtDecode } from 'jwt-decode';
 
const Shift = () => {

  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    const token = Cookies.get('token');
       if (!token) {
      navigate('/');
    } 
  }, []);
  useEffect(() => {
    const token = Cookies.get('token');
    if (!token) {
      navigate('/');
    } else {
      const decoded = jwtDecode(token);
      setUsername(decoded.username);
      setRole(decoded.role);
      if (decoded.role !== 'AdminHome') {
        navigate('/');
      }
    }
  }, []);

  const [addShiftOpen, setAddShiftOpen] = useState(false);
  const [newShiftId, setNewShiftId] = useState('');
  const [newShiftName, setNewShiftName] = useState('');
  const [newShiftTiming, setNewShiftTiming] = useState('');
  const [newShiftEnd, setNewShiftEnd] = useState('');
  const [shiftDuration, setShiftDuration] = useState('');
  const [newBreak, setNewBreak] = useState('');
  const [newDescription, setNewDescription] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [anchorEl, setAnchorEl] = useState(null);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [data, setData] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); 
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editedRow, setEditedRow] = useState(null);
 
  const fetchData = () => {
    axios
    .get(`https://api.payroll.yatayati.com/shift/time/${username}`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching shift:", error);
      });
  };
 
  useEffect(() => {
    if(username){
      fetchData();
    }
  }, [username]);
 
  const handleOpenAddShiftDialog = () => {
    setAddShiftOpen(true);
  };
 
  const handleCloseAddShiftDialog = () => {
    setAddShiftOpen(false);
  };
 
  const handleAddShift = () => {
    if (!newShiftName || !newShiftTiming || !newShiftEnd || !newBreak) {
      toast.warning("Please Fill Name, Start&End Time, Break");
      return;
    }
    const newShift = {
      ShiftId: newShiftId,
      ShiftName: newShiftName,
      StartTime: newShiftTiming,
      EndTime: newShiftEnd,
      ShiftDuration: calculateShiftDuration(newShiftTiming, newShiftEnd),
      BreakDuration: newBreak,
      Description: newDescription,
      OfficeID: username,
    };
 
    axios
      .post("https://api.payroll.yatayati.com/shift/Post", newShift)
      .then(() => {
        // Fetch new data after successfully adding the shift
        fetchData();
        setNewShiftId('');
        setNewShiftName('');
        setNewShiftTiming('');
        setNewShiftEnd('');
        setShiftDuration('');
        setNewBreak('');
        setNewDescription('');
        handleCloseAddShiftDialog();
        toast.success("Shift added successfully");
      })
      .catch((error) => {
        console.error("Error adding shift:", error);
        toast.error("Failed to add shift");
      });
  };
 
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
 
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
 
  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };
 
  const handleFormSubmit = async (event) => {
    event.preventDefault();
 
    try {
      handleClose();
 
      const response = await axios.put(
        `https://api.payroll.yatayati.com/shift/${editedRow.ShiftId}`,  // Ensure editedRow is properly set
        {
          ShiftId: editedRow.ShiftId,
          ShiftName: editedRow.ShiftName,
          StartTime: editedRow.StartTime,
          EndTime: editedRow.EndTime,
          ShiftDuration: editedRow.ShiftDuration,
          BreakDuration: editedRow.BreakDuration,
          Description: editedRow.Description,
        }
      );
 
      console.log("PUT request response:", response.data);
 
      setData((prevData) =>
        prevData.map((item) => (item.ShiftId === editedRow.ShiftId ? editedRow : item))
      );
      fetchData();
      setEditDialogOpen(false);
      setEditedRow(null);
      toast.success("Record updated successfully");
      
    } catch (error) {
      console.error("Error updating record:", error);
      toast.error("Failed to update record");
    }
  };
 
  const handleDeleteClicked = (row) => {
    setSelectedRow(row); // Set the selected row for deletion
    setDeleteConfirmationOpen(true); // Open the confirmation dialog
  };
 
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };
 
  const handleEditFormChange = (event, field) => {
    const { value } = event.target;
    setEditedRow((prev) => ({
      ...prev,
      [field]: value,
    }));
  
    if (field === 'StartTime' || field === 'EndTime') {
      const start = field === 'StartTime' ? value : editedRow.StartTime;
      const end = field === 'EndTime' ? value : editedRow.EndTime;
      if (start && end) {
        const startTime = new Date(`01/01/2000 ${start}`);
        let endTime = new Date(`01/01/2000 ${end}`);
        if (endTime < startTime) {
          endTime.setDate(endTime.getDate() + 1);
        }
        const duration = (endTime - startTime) / (1000 * 60);
        const hours = Math.floor(duration / 60);
        const minutes = duration % 60;
        const shiftDuration = `${hours} hours ${minutes} minutes`;
  
        setEditedRow((prev) => ({
          ...prev,
          ShiftDuration: shiftDuration,
        }));
      }
    }
  };
  
 
  const handleEditOpen = (row) => {
    setEditedRow(row);
    setEditDialogOpen(true);
  };
 
  const deleteRow = (shiftId) => {
    axios
      .delete(`https://api.payroll.yatayati.com/shift/${shiftId}`)
      .then(() => {
        fetchData();
        setAnchorEl(null);
        setDeleteConfirmationOpen(false);
        handleClose();
        toast.success("Record deleted successfully");
 
      })
      .catch((error) => {
        console.error("Error deleting record:", error);
        toast.error("Failed to delete record");
      });
  };
 
  const handleDeleteConfirmed = () => {
    if (selectedRow) {
      deleteRow(selectedRow.ShiftId); // Delete the row if confirmed
      setDeleteConfirmationOpen(false); // Close the confirmation dialog
    }
  };
 
  const handleDeleteCancelled = () => {
    setSelectedRow(null); // Clear the selected row
    setDeleteConfirmationOpen(false); // Close the confirmation dialog
  };
 
  const calculateShiftDuration = (startTime, endTime) => {
    if (startTime && endTime) {
      const start = new Date(`01/01/2000 ${startTime}`);
      let end = new Date(`01/01/2000 ${endTime}`);
     
      if (end < start) {
        end.setDate(end.getDate() + 1);
      }
     
      const duration = (end - start) / (1000 * 60);
      const hours = Math.floor(duration / 60);
      const minutes = duration % 60;
      return `${hours} hours ${minutes} minutes`;
    }
    return '';
  };
 
  useEffect(() => {
    if (newShiftTiming && newShiftEnd) {
      setShiftDuration(calculateShiftDuration(newShiftTiming, newShiftEnd));
    }
  }, [newShiftTiming, newShiftEnd]);
 
  const handleClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };
 
  let filteredData = [...data];
 
  const totalPages = Math.ceil(filteredData.length / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, filteredData.length);
  const currentData = filteredData.slice(startIndex, endIndex);
 
  return (
    <>
    <AdminSidebar/>
    <Week/>
    <div style={{ overflowX: 'auto', maxWidth: '100%', padding:'15px'}}>
      <div>
        <div>
          <div style={{display:'flex', marginBottom:'20px', justifyContent:'end'}}>
        <Button variant="contained" color="primary" size="small" onClick={handleOpenAddShiftDialog}>
                + Add Shift Type
          </Button>
          </div>
          <Dialog open={addShiftOpen} onClose={handleCloseAddShiftDialog}>
            <DialogTitle>Add New Shifts</DialogTitle>
            <DialogContent>
              <TextField
              label="Shift Name"
              value={newShiftName}
              onChange={(e) => setNewShiftName(e.target.value)}
              fullWidth margin='normal'/>
 
              <TextField
              type='time'
              label="Shift Start"
              value={newShiftTiming}
              onChange={(e) => setNewShiftTiming(e.target.value)}
              fullWidth margin='normal'
              InputLabelProps={{
                shrink: true,
              }}/>
 
              <TextField
              type='time'
              label="Shift End"
              value={newShiftEnd}
              onChange={(e) => setNewShiftEnd(e.target.value)}
              fullWidth margin='normal'
              InputLabelProps={{
                shrink: true,
              }}/>
 
              <TextField
                  label="Shift Duration"
                  value={shiftDuration}
                  disabled
                  fullWidth margin='normal'/>
 
              <TextField
              label="Break Duration"
              value={newBreak}
              onChange={(e) => setNewBreak(e.target.value)}
              fullWidth margin='normal'/>
 
              <TextField
              label="Description"
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
              fullWidth margin='normal'/>
 
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseAddShiftDialog} color="secondary">
                Cancel
              </Button>
              <Button onClick={handleAddShift} color="primary">
                Add
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
 
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead style={{ backgroundColor: 'rgb(167, 165, 165)' }}>
            <TableRow>
              <TableCell>Shift Name</TableCell>
              <TableCell>Start Time</TableCell>
              <TableCell>End Time</TableCell>
              <TableCell>Shift Duration</TableCell>
              <TableCell>Break Duration</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.ShiftName}</TableCell>
                <TableCell>{row.StartTime}</TableCell>
                <TableCell>{row.EndTime}</TableCell>
                <TableCell>{row.ShiftDuration}</TableCell>
                <TableCell>{row.BreakDuration}</TableCell>
                <TableCell>{row.Description ? row.Description : 'None'}</TableCell>
                <TableCell>
                  <Button onClick={(event) => handleClick(event, row)}>
                  <MoreHorizIcon />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
 
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
        <div>
          <Button disabled={currentPage === 1} onClick={handlePrevPage}><FaChevronLeft /></Button>
          <span style={{ margin: '0 10px' }}>Page {currentPage} of {totalPages}</span>
          <Button disabled={currentPage === totalPages} onClick={handleNextPage}><FaChevronRight /></Button>
        </div>
        <Typography variant="body2">
          {' '}
          <select value={rowsPerPage} onChange={handleRowsPerPageChange} style={{ width: '40px', height: '28px' }}>
            <option value={4}>4</option>
            <option value={8}>8</option>
            <option value={12}>12</option>
          </select>
        </Typography>
      </div>
 
      {selectedRow && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
          <MenuItem onClick={() => handleEditOpen(selectedRow)}>Edit</MenuItem>
          <MenuItem onClick={() => handleDeleteClicked(selectedRow)}>Delete</MenuItem> {/* Pass selectedRow to handleDeleteClicked */}
        </Menu>
      )}

      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)}>
          <DialogTitle>Edit Details</DialogTitle>
          <DialogContent>
            <form onSubmit={handleFormSubmit}>
              <TextField
                label="Shift Name :"
                value={editedRow ? editedRow.ShiftName : ""}
                onChange={(e) => handleEditFormChange(e, "ShiftName")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Shift Start:"
                type='time'
                value={editedRow ? editedRow.StartTime : ""}
                onChange={(e) => handleEditFormChange(e, "StartTime")}
                fullWidth
                margin="normal"
              />
 
              <TextField
                label="Shift End:"
                type='time'
                value={editedRow ? editedRow.EndTime : ""}
                onChange={(e) => handleEditFormChange(e, "EndTime")}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Shift Duration:"
                value={editedRow ? editedRow.ShiftDuration : ""}
                onChange={(e) => handleEditFormChange(e, "ShiftDuration")}
                fullWidth
                margin="normal"
                disabled
              />
 
              <TextField
                label="Shift break:"
                value={editedRow ? editedRow.BreakDuration : ""}
                onChange={(e) => handleEditFormChange(e, "BreakDuration")}
                fullWidth
                margin="normal"
              />
 
              <TextField
                label="Description:"
                value={editedRow ? editedRow.Description : ""}
                onChange={(e) => handleEditFormChange(e, "Description")}
                fullWidth
                margin="normal"
              />
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setEditDialogOpen(false)} color="secondary">
              Cancel
            </Button>
            <Button onClick={handleFormSubmit} color="primary">
              Update
            </Button>
          </DialogActions>
        </Dialog>
 
      <Dialog open={deleteConfirmationOpen} onClose={handleDeleteCancelled}>
        <DialogTitle>Confirmation</DialogTitle>
        <DialogContent>
          Are you sure you want to delete this record?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancelled} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirmed} color="primary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
 
      <ToastContainer />
    </div>
    </>
  );
}
 
export default Shift;
 